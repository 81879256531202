import '/assets/styles/pages/account/loyalty-info.scss';
import { useEffect, useState } from 'react';
import config from '/config';

// Partials
import Img from '@wearetla/tla-essentials-tools/partials/img';
import Loader from '/views/partials/loader';
import Pricebox from '/views/partials/pricebox';

// Services
import saleServices from '/services/sale';

// Static Assets
import image_loyalty_info_banner from '/assets/images/loyalty-info/loyalty-info-banner.jpg';

const LoyaltyInfo = () => {
	const [loyaltyPointsPayload, setLoyaltyPointsPayload] = useState(null);

	useEffect(() => {
		if (config.enableLoyalty) {
			saleServices.getLoyaltyPoints().then((payload) => {
				setLoyaltyPointsPayload(payload);
			});
		}
	}, []);

	return (
		<>
			<div className="page-head">
				<h1 className="head-title">Babymall Kalbim Kart Programı</h1>
			</div>
			<div className="page-content">
				<div className="info-imagewrap">
					<Img src={image_loyalty_info_banner} alt="Babymall Kalbim Kart Programı" />
				</div>

				<div className="info-status loader-container low">
					<Loader className="status-loader" solid loading={!loyaltyPointsPayload} />
					<div className="status-heading">
						Hesabınıza tanımlı{' '}
						<Pricebox className="status-points" price={loyaltyPointsPayload?.balance ?? 0} />{' '}
						değerinde Kalbim Kart puanınız var!
					</div>

					<div className="status-message">
						{loyaltyPointsPayload?.balance ? (
							<>
								Siparişinizin <Pricebox price={loyaltyPointsPayload.balance} />
								'lik tutarını puanlarınızla ödeyebilirsiniz.
							</>
						) : (
							<>
								BabyMall Kalbim Kart üyeleri, her alışverişte puan kazanıp sonraki işlemlerinde
								kullanarak avantajlı alışverişin tadını çıkarıyorlar. "babymall.com.tr" üzerinden
								verdiğiniz siparişlerde, puanlarınız ürünleriniz kargoya verildikten 24 saat sonra
								hesabınıza tanımlanır. Kalbim Kart üyelik koşullarına göre; bebek bezi, mama ve
								ıslak mendil ürünlerinde puan kazanımı ve kullanımı geçerli değildir.
							</>
						)}
					</div>
				</div>

				<div className="info-content wysiwyg">
					<h2>Babymall Fırsatlar Dünyasının Kalbiyle Tanışın!</h2>

					<p>
						Babymall Kalbim Kart, yıl boyunca sizlere özel teklifler, sezonluk indirimler, indirim
						çekleri ve sürpriz hediyeler sunarak alışveriş deneyiminizi özelleştiriyor.
					</p>
					<p>
						Kalbim Kartlı olmak için tek yapmanız gereken, bir Babymall mağazasından, Babymall mobil
						uygulamasından veya babymall.com.tr’den <strong>üye olmak</strong>.
					</p>
					<p>
						<strong>Sadece size özel bir dünyanın anahtarı!</strong>
					</p>

					<h2>Kalbim Kart Dünyasının Avantajları</h2>

					<ul>
						<li>
							<strong>Puan Kazanma:</strong> Her alışverişinizde %1 TL değerinde puan kazanarak bir
							sonraki alışverişlerinizde kullanın.
						</li>
						<li>
							<strong>Öncelikli Bilgilendirme:</strong> Mağazalarımızdaki kampanya ve etkinliklerden
							ilk siz haberdar olun.
						</li>
						<li>
							<strong>Kişiye Özel Teklifler:</strong> Yıl boyunca sadece size özel hazırlanan
							tekliflerden faydalanın.
						</li>
						<li>
							<strong>Sezonluk İndirimler:</strong> Alışveriş mevsimlerinde size özel sezonluk
							indirimlerle, istediğiniz ürünleri daha uygun fiyatlara bulun.
						</li>
						<li>
							<strong>İndirim Çekleri:</strong> Alışverişlerinizle birlikte kazanacağınız indirim
							çekleriyle, sonraki alışverişlerinizde avantajlı fiyatlarla satın alma şansını
							yakalayın.
						</li>
						<li>
							<strong>Sürpriz Hediyeler:</strong> Babymall Kalbim Kart'ınızla yapacağınız
							alışverişlerde, sizi ve sevdiklerinizi mutlu edecek sürpriz hediyeler kazanın.
						</li>
					</ul>

					<h2>Kalbim Kartlı Olmak Kazandırıyor!</h2>

					<ul>
						<li>Kalbim Kart üyeleri puan kazanabilir ve kullanabilir.</li>
						<li>
							Kazanılan puanlar, yalnızca sonraki alışverişlerde indirim olarak kullanılabilir.
						</li>
						<li>
							Kalbim Kart, sadece kayıtlı olduğu üyeye aittir ve başkası tarafından kullanılamaz.
						</li>
						<li>
							Bebek maması, bebek bezi, ıslak mendil, poşet, toplu siparişler, gift card kullanımı
							ve alımları ile hizmet bedelleri (kargo ücreti, nakliye bedeli, hediye paketi vb.)
							Kalbim Kart avantajlarına dahil değildir.
						</li>
						<li>Kalbim Kart avantajları diğer promosyonlarla birleştirilemez.</li>
						<li>
							Kalbim Kart puanları yıl sonuna kadar geçerlidir. Yıl sonuna kadar kullanılmayan
							puanlar devredilemez.
						</li>
						<li>
							Babymall’da uygulanan dönemsel kampanya ve indirimler arasından sizin için en
							avantajlı olan uygulanacaktır.
						</li>
						<li>
							Babymall, puan kazanımı ve kampanya koşullarında istediği zaman değişiklik yapma
							hakkını saklı tutar.
						</li>
					</ul>

					<h2>Puanlarımı Nasıl Kullanabilirim?</h2>

					<p>
						Babymall.com.tr’de, Babymall mobil uygulamasında veya Babymall mağazalarında Kalbim Kart
						puanlarınızı kullanabilirsiniz.
					</p>
					<p>
						Babymall web sitesinde veya mobil uygulamasında puanlarınızı kullanmak için satın almak
						istediğiniz ürünleri sepetinize ekleyerek ödeme adımında kolayca puanlarınızı
						kullanabilirsiniz.
					</p>
					<p>
						Babymall mağazalarında puanlarınızı kullanmak için; kasa noktasındaki görevliye ödeme
						esnasında Kalbim Kart puanlarınızı kullanmak istediğinizi söylemeniz yeterlidir.
					</p>
					<p>Detaylı bilgi için yardım sayfamıza göz atabilirsiniz.</p>
				</div>
			</div>
		</>
	);
};

export default LoyaltyInfo;
